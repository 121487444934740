import { CountryCode } from './countries'

export enum CurrencyCode {
  USDollar = 'USD',
  CADollar = 'CAD',
  Euro = 'EUR',
  Pound = 'GBP',
  DanishKrone = 'DKK',
  PolishZloty = 'PLN',
  SouthAfricanRand = 'ZAR',
  NZDollar = 'NZD',
  CzechKoruna = 'CZK',
  MexicanPeso = 'MXN',
  IndianRupee = 'INR',
  PeruvianSol = 'PEN',
  ChileanPeso = 'CLP',
  ArgentinePeso = 'ARS',
  ColombianPeso = 'COP',
  NigerianNaira = 'NGN',
  JapaneseYen = 'JPY',
  UruguayanPeso = 'UYU',
  BrazilianReal = 'BRL',
  AustralianDollar = 'AUD',
  UAEDirham = 'AED',
  SwissFranc = 'CHF',
  KenyanShilling = 'KES',
  ThaiBaht = 'THB',
  PhilippinePeso = 'PHP',
  IsraelShekel = 'ISL',
  GeorgianLari = 'GEL'
}

export const CurrencySymbol: Record<CurrencyCode, string> = {
  USD: '$',
  EUR: '€',
  CAD: '$',
  GBP: '£',
  DKK: 'Kr',
  PLN: 'zł',
  ZAR: 'R',
  NZD: '$',
  CZK: 'Kč',
  MXN: '$',
  INR: '₹',
  PEN: 'S/',
  CLP: '$',
  ARS: '$',
  COP: '$',
  NGN: '₦',
  JPY: '¥',
  UYU: '$',
  BRL: 'R$',
  AUD: '$',
  AED: 'Dhs',
  CHF: 'CHF',
  KES: 'KSh',
  THB: '฿',
  PHP: '₱',
  ISL: '₪',
  GEL: '₾'
}

export const getCurrency = (country: CountryCode) => {
  switch (country) {
    case CountryCode.US:
    case CountryCode.ElSalvador:
    case CountryCode.Ecuador:
      return CurrencyCode.USDollar
    case CountryCode.Canada:
      return CurrencyCode.CADollar
    case CountryCode.Poland:
      return CurrencyCode.PolishZloty
    case CountryCode.UnitedKingdom:
      return CurrencyCode.Pound
    case CountryCode.Denmark:
      return CurrencyCode.DanishKrone
    case CountryCode.Germany:
    case CountryCode.Spain:
    case CountryCode.France:
    case CountryCode.Ireland:
    case CountryCode.Italy:
    case CountryCode.Netherlands:
    case CountryCode.Portugal:
    case CountryCode.Sweden:
    case CountryCode.Austria:
    case CountryCode.Slovakia:
    case CountryCode.Slovenia:
    case CountryCode.Belgium:
    case CountryCode.Greece:
      return CurrencyCode.Euro
    case CountryCode.SouthAfrica:
      return CurrencyCode.SouthAfricanRand
    case CountryCode.NewZealand:
      return CurrencyCode.NZDollar
    case CountryCode.CzechRepublic:
      return CurrencyCode.CzechKoruna
    case CountryCode.Mexico:
      return CurrencyCode.MexicanPeso
    case CountryCode.India:
      return CurrencyCode.IndianRupee
    case CountryCode.Peru:
      return CurrencyCode.PeruvianSol
    case CountryCode.Chile:
      return CurrencyCode.ChileanPeso
    case CountryCode.Argentina:
      return CurrencyCode.ArgentinePeso
    case CountryCode.Colombia:
      return CurrencyCode.ColombianPeso
    case CountryCode.Nigeria:
      return CurrencyCode.NigerianNaira
    case CountryCode.Japan:
      return CurrencyCode.JapaneseYen
    case CountryCode.Uruguay:
      return CurrencyCode.UruguayanPeso
    case CountryCode.Brazil:
      return CurrencyCode.BrazilianReal
    case CountryCode.Australia:
      return CurrencyCode.AustralianDollar
    case CountryCode.UnitedArabEmirates:
      return CurrencyCode.UAEDirham
    case CountryCode.Switzerland:
      return CurrencyCode.SwissFranc
    case CountryCode.Kenya:
      return CurrencyCode.KenyanShilling
    case CountryCode.Thailand:
      return CurrencyCode.ThaiBaht
    case CountryCode.Philippines:
      return CurrencyCode.PhilippinePeso
    case CountryCode.Israel:
      return CurrencyCode.IsraelShekel
    case CountryCode.Georgia:
      return CurrencyCode.GeorgianLari
  }
}

export const isValidCurrencyCode = (code: string) => {
  return Object.values<string>(CurrencyCode).includes(code)
}

export const convertCurrencyCodeAmountToSymbolAmount = (amount: string) => {
  const currencyCode = amount.slice(0, 3) as CurrencyCode
  const currencySymbol = CurrencySymbol[currencyCode]
  const fee = amount.slice(3)

  return `${currencySymbol}${fee}`
}
